"use client";
import React from "react";

interface EloquaDataContextType {
  eloquaData: any;
  setEloquaData: React.Dispatch<React.SetStateAction<any>>;
}

const EloquaDataContext = React.createContext<
  EloquaDataContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

export const EloquaDataContextProvider = ({ children }: Props) => {
  const [eloquaData, setEloquaData] = React.useState({});

  return (
    <EloquaDataContext.Provider value={{ eloquaData, setEloquaData }}>
      {children}
    </EloquaDataContext.Provider>
  );
};

export const useEloquaData = () => React.useContext(EloquaDataContext);
